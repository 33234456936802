import React, {useEffect, useState} from 'react';

import { RouteComponentProps } from 'react-router';
import styles from '../componentsStyle/avatar.module.css';
import DemeritList from '../newcomponents/demerit/demeritlist';
import AllStudents from './controls/allstudents';
import ManageRegular from './controls/manageregular';
import NewRecruit from './new/newrecruit';
import WaitingList from './controls/waitinglist';
import { Socket } from 'socket.io-client';
import SeatList from './controls/seatlist';
import SeatListForWinter from './controls/seatlistforwinter';

type currentSideBarMenuList = "avatar" | "home" | "notification" | "alarm" | "edit" | "book" | "question" | "restaurant" | "envelope" | "search" | "chart" | "attendance" | "출석 관리 보고";

interface user {
    name: string;
    value: "student" | "teacher" | "parent" | "staff";
    id: number;
}


interface avatarProps extends RouteComponentProps {
    activateMenuList : (curret : currentSideBarMenuList) => void;
    socket : Socket;
    user: user | null | undefined;
}


const Avatar : React.FC<avatarProps> = (props) => {
    const [searchMenu, setSearchMenu] = useState("write");

    useEffect(()=>{
        props.activateMenuList("avatar");
    }, [])

    return(
        <div className={styles.main}>
            <div className={styles.mainBoard}>
                <div className={styles.title}>
                    <img src="img/off/avatar.svg" alt="avatar" /> 학생정보
                </div>

                <div className={styles.searchMenu}>
                    <div onClick={(e) => { setSearchMenu("write") }} className={`${styles.searchMenuDiv} ${searchMenu === "write" ? styles.active : ""}`}>
                        전체 학생정보
                    </div>
                    <div onClick={(e) => { setSearchMenu("seat") }} className={`${styles.searchMenuDiv} ${searchMenu === "seat" ? styles.active : ""}`}>
                        좌석표
                    </div>
                    <div onClick={(e) => { setSearchMenu("watch") }} className={`${styles.searchMenuDiv} ${searchMenu === "watch" ? styles.active : ""}`}>
                        신규/퇴원/자리이동
                    </div>
                    <div onClick={(e) => { setSearchMenu("regular") }} className={`${styles.searchMenuDiv} ${searchMenu === "regular" ? styles.active : ""}`}>
                        정기일정 관리
                    </div>
                    <div onClick={(e) => { setSearchMenu("demerit") }} className={`${styles.searchMenuDiv} ${searchMenu === "demerit" ? styles.active : ""}`}>
                        벌점내역 보기
                    </div>
                    <div onClick={(e) => { setSearchMenu("waiting") }} className={`${styles.searchMenuDiv} ${searchMenu === "waiting" ? styles.active : ""}`}>
                        대기자 관리
                    </div>
                    <div onClick={(e) => { setSearchMenu("winter") }} className={`${styles.searchMenuDiv} ${searchMenu === "winter" ? styles.active : ""}`}>
                        윈터 좌석 관리
                    </div>
                </div>

                <div>
                    { searchMenu === "write" && 
                        <AllStudents socket={props.socket} user={props.user} />
                    }
                    {
                        searchMenu === "seat" &&
                        <SeatList socket={props.socket} user={props.user} />
                    }
                    {
                        searchMenu === "regular" &&
                        <ManageRegular user={props.user} />
                    }
                    {
                        searchMenu === "watch" &&
                        <NewRecruit user={props.user} socket={props.socket} />
                    }
                    {
                        searchMenu === "demerit" &&
                        <DemeritList user={props.user} />
                    }
                    {
                        searchMenu === "waiting" &&
                        <WaitingList />
                    }
                    {
                        searchMenu === "winter" &&
                        <SeatListForWinter socket={props.socket} user={props.user} />
                    }
                </div>
                
            </div>
        </div>
    )
}

export default Avatar;