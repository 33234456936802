import DaechiSeatSvg from "../dashboard/components/etc/daechiseatsvg";
import GangnamSeatSvg from "../dashboard/components/etc/gangnamseatsvg";
import Daechi2SeatSvg from "../dashboard/components/etc/daechi2seatsvg";
import Daechi3SeatSvg from "../dashboard/components/etc/daechi3seatsvg";
import SongdoSeatSvg from "../dashboard/components/etc/songdoseat";
import { filter } from "lodash";
import SongdoFreeSeatSvg from "../dashboard/components/etc/songdofreeseatsvg";
import SongdoFixedSeatSvg from "../dashboard/components/etc/songdofixedseatsvg";
import SongdoFreeSeatSvg2 from "../dashboard/components/etc/songdofreeseatsvg2";



export interface ClassInfo {
    classNumber: string;
    start: {
        hours: number;
        minutes: number;
    };
    end: {
        hours: number;
        minutes: number;
    };
    isFirst?: boolean;
    isLast?: boolean;
    isSaturedayLast?: boolean;
};

interface ClassInfos{
    one : ClassInfo[];
    two : ClassInfo[];
    three : ClassInfo[];
    four : ClassInfo[];
}

interface LocationData {
    academy : string[]
    korean : string,
    english : string,
    filterValue_contains? : string,
    jobs? : any[],
    seatSvg? : any,
    questionLocation? : any[],
    classInfo? : ClassInfo[],
    isFree? : boolean
}

const classInfos : ClassInfos = {
    one : [
        {
            classNumber: "zero",
            start: {
                hours: 8,
                minutes: 0
            },
            end: {
                hours: 8,
                minutes: 45
            },
            isFirst : true
        },
        {
            classNumber: "first",
            start: {
                hours: 9,
                minutes: 0
            },
            end: {
                hours: 10,
                minutes: 15
            }
        },
        {
            classNumber: "second",
            start: {
                hours: 10,
                minutes: 30
            },
            end: {
                hours: 11,
                minutes: 45
            }
        },
        {
            classNumber: "third",
            start: {
                hours: 13,
                minutes: 0
            },
            end: {
                hours: 14,
                minutes: 15
            }
        },
        {
            classNumber: "fourth",
            start: {
                hours: 14,
                minutes: 30
            },
            end: {
                hours: 15,
                minutes: 45
            },
            isSaturedayLast : true
        },
        {
            classNumber: "fifth",
            start: {
                hours: 16,
                minutes: 0
            },
            end: {
                hours: 17,
                minutes: 15
            }
        },
        {
            classNumber: "sixth",
            start: {
                hours: 18,
                minutes: 30
            },
            end: {
                hours: 19,
                minutes: 30
            }
    
        },
        {
            classNumber: "seventh",
            start: {
                hours: 19,
                minutes: 45
            },
            end: {
                hours: 20,
                minutes: 45
            }
    
        },
        {
            classNumber: "eighth",
            start: {
                hours: 21,
                minutes: 0
            },
            end: {
                hours: 22,
                minutes: 0
            },
            isLast : true
        },
        {
            classNumber : "lunch",
            start : {
                hours : 11,
                minutes : 45
            },
            end : {
                hours : 13,
                minutes : 0
            }
        },
        {
            classNumber : "dinner",
            start : {
                hours : 17,
                minutes : 15
            },
            end : {
                hours : 18,
                minutes : 30
            }
        }
    
    ],
    two : [
        {
            classNumber: "first",
            start: {
                hours: 8,
                minutes: 0
            },
            end: {
                hours: 8,
                minutes: 50
            },
            isFirst : true
        },
        {
            classNumber: "second",
            start: {
                hours: 9,
                minutes: 0
            },
            end: {
                hours: 9,
                minutes: 50
            }
        },
        {
            classNumber: "third",
            start: {
                hours: 10,
                minutes: 0
            },
            end: {
                hours: 10,
                minutes: 50
            }
        },
        {
            classNumber: "fourth",
            start: {
                hours: 11,
                minutes: 0
            },
            end: {
                hours: 12,
                minutes: 0
            }
    
        },
        {
            classNumber: "fifth",
            start: {
                hours: 13,
                minutes: 0
            },
            end: {
                hours: 13,
                minutes: 50
            }
        },
        {
            classNumber: "sixth",
            start: {
                hours: 14,
                minutes: 0
            },
            end: {
                hours: 14,
                minutes: 50
            }
    
        },
        {
            classNumber: "seventh",
            start: {
                hours: 15,
                minutes: 0
            },
            end: {
                hours: 15,
                minutes: 50
            }
    
        },
        {
            classNumber: "eighth",
            start: {
                hours: 16,
                minutes: 0
            },
            end: {
                hours: 16,
                minutes: 50
            }
        },
        {
            classNumber : "ninth",
            start : {
                hours : 17,
                minutes : 0
            },
            end : {
                hours : 17,
                minutes : 50
            }
        },
        {
            classNumber : "tenth",
            start : {
                hours : 19,
                minutes : 0
            },
            end : {
                hours : 19,
                minutes : 50
            }
        },
        {
            classNumber : "eleventh",
            start : {
                hours : 20,
                minutes : 0
            },
            end : {
                hours : 20,
                minutes : 50
            }
        },
        {
            classNumber : "twelfth",
            start : {
                hours : 21,
                minutes : 0
            },
            end : {
                hours : 21,
                minutes : 50
            },
            isSaturedayLast : true
        },
        {
            classNumber : "thirteenth",
            start : {
                hours : 22,
                minutes : 0
            },
            end : {
                hours : 22,
                minutes : 50
            }
        },
        {
            classNumber : "fourteenth",
            start : {
                hours : 23,
                minutes : 0
            },
            end : {
                hours : 23,
                minutes : 50
            },
            isLast : true
        },
        {
            classNumber : "lunch",
            start : {
                hours : 12,
                minutes : 0
            },
            end : {
                hours : 13,
                minutes : 0
            }
        },
        {
            classNumber : "dinner",
            start : {
                hours : 18,
                minutes : 0
            },
            end : {
                hours : 19,
                minutes : 0
            }
        }
    ],
    three : [
        {
            classNumber : "first",
            start : {
                hours : 8,
                minutes : 0
            },
            end : {
                hours : 9,
                minutes : 50
            },
            isFirst : true
        },
        {
            classNumber : "second",
            start : {
                hours : 10,
                minutes : 0
            },
            end : {
                hours : 12,
                minutes : 0
            }
        },
        {
            classNumber : "third",
            start : {
                hours : 13,
                minutes : 0
            },
            end : {
                hours : 14,
                minutes : 50
            }
        },
        {
            classNumber : "fourth",
            start : {
                hours : 15,
                minutes : 0
            },
            end : {
                hours : 16,
                minutes : 50
            }
        },
        {
            classNumber : "fifth",
            start : {
                hours : 17,
                minutes : 0
            },
            end : {
                hours : 18,
                minutes : 0
            }
        },
        {
            classNumber : "sixth",
            start : {
                hours : 19,
                minutes : 0
            },
            end : {
                hours : 20,
                minutes : 50
            }
        },
        {
            classNumber : "seventh",
            start : {
                hours : 21,
                minutes : 0
            },
            end : {
                hours : 21,
                minutes : 50
            },
            isSaturedayLast : true
        },
        {
            classNumber : "eighth",
            start : {
                hours : 22,
                minutes : 0
            },
            end : {
                hours : 23,
                minutes : 59
            },
            isLast : true
        },
        {
            classNumber : "lunch",
            start : {
                hours : 12,
                minutes : 0
            },
            end : {
                hours : 13,
                minutes : 0
            }
        },
        {
            classNumber : "dinner",
            start : {
                hours : 18,
                minutes : 0
            },
            end : {
                hours : 19,
                minutes : 0
            }
        }
    ],
    four : [
        {
            classNumber : "first",
            start : {
                hours : 9,
                minutes : 0
            },
            end : {
                hours : 10,
                minutes : 20
            }
        },
        {
            classNumber : "second",
            start : {
                hours : 10,
                minutes : 40
            },
            end : {
                hours : 12,
                minutes : 0
            }
        },
        {
            classNumber : "third",
            start : {
                hours : 13,
                minutes : 10
            },
            end : {
                hours : 14,
                minutes : 30
            }
        },
        {
            classNumber : "fourth",
            start : {
                hours : 14,
                minutes : 50
            },
            end : {
                hours : 16,
                minutes : 10
            }
        },
        {
            classNumber : "fifth",
            start : {
                hours : 16,
                minutes : 30
            },
            end : {
                hours : 17,
                minutes : 50
            }
        },
        {
            classNumber : "sixth",
            start : {
                hours : 19,
                minutes : 0
            },
            end : {
                hours : 20,
                minutes : 20
            }
        },
        {
            classNumber : "seventh",
            start : {
                hours : 20,
                minutes : 40
            },
            end : {
                hours : 22,
                minutes : 0
            }
        },
        {
            classNumber : "lunch",
            start : {
                hours : 12,
                minutes : 0
            },
            end : {
                hours : 13,
                minutes : 10
            }
        },
        {
            classNumber : "dinner",
            start : {
                hours : 17,
                minutes : 50
            },
            end : {
                hours : 19,
                minutes : 0
            }
        }
    ]
}

const totalLocationData : LocationData[] = [
    {   
        academy : ["sunbae", "mom"],
        korean : "전체",
        english : "total",
        filterValue_contains : "",
        jobs : [
            {
                english : "total",
                korean : "전체",
                filterValue_contains : "",
                index : false 
            }
        ],
    },
    {
        academy : ["sunbae"],
        korean : "강남점",
        english : "gangnam",
        filterValue_contains : "강남점",
        jobs : [
            {
                english : "desk",
                korean : "강남 데스크",
                filterValue_contains : "강남 데스크",
                index : 0
            },
            {
                english : "coach",
                korean : "강남 사감",
                filterValue_contains : "강남 사감",
                index : 1
            },
        ],
        seatSvg : GangnamSeatSvg,
        questionLocation : [
            {
                korean : "강남점",
                english : "gangnam",
            }
        ],
        classInfo : classInfos.one
    },
    {
        academy : ["sunbae"],
        korean : "대치점",
        english : "daechi",
        filterValue_contains : "대치점",
        jobs : [
            {
                english : "desk2",
                korean : "대치 데스크",
                filterValue_contains : "대치 데스크",
                index : 2
            },
            {
                english : "coach2",
                korean : "대치 사감",
                filterValue_contains : "대치 사감",
                index : 3
            },
        ],
        seatSvg : DaechiSeatSvg,
        questionLocation : [
            {
                korean : "대치점",
                english : "daechi",
            },
            {
                korean : "대치2,3층",
                english : "daechi_12",
            },
            {
                korean : "대치2,6층",
                english : "daechi_13",
            },
            {
                korean : "대치점 전체",
                english : "daechiTotal",
            }
        ],
        classInfo : classInfos.one
    },
    {
        academy : ["sunbae"],
        korean : "대치3층",
        english : "daechi2",
        filterValue_contains : "대치3층",
        jobs : [
            {
                english : "desk3",
                korean : "대치 3층 데스크",
                filterValue_contains : "대치 3층 데스크",
                index : 4
            },
            {
                english : "coach3",
                korean : "대치 3층 사감",
                filterValue_contains : "대치 3층 사감",
                index : 5 
            },
        ],
        seatSvg : Daechi2SeatSvg,
        questionLocation : [
            {
                korean : "대치3층",
                english : "daechi2",
            },
            {
                korean : "대치3,6층",
                english : "daechi_23",
            },
            {
                korean : "대치2,3층",
                english : "daechi_12",
            },
            {
                korean : "대치점 전체",
                english : "daechiTotal",
            }
        ],
        classInfo : classInfos.one
    },
    {
        academy : ["sunbae"],
        korean : "대치6층",
        english : "daechi3",
        filterValue_contains : "대치6층",
        jobs : [
            {
                english : "desk4",
                korean : "대치 6층 데스크",
                filterValue_contains : "대치 6층 데스크",
                index : 6
            },
            {
                english : "coach4",
                korean : "대치 6층 사감",
                filterValue_contains : "대치 6층 사감",
                index : 7
            }
        ],
        seatSvg : Daechi3SeatSvg,
        questionLocation : [
            {
                korean : "대치6층",
                english : "daechi3",
            },
            {
                korean : "대치3,6층",
                english : "daechi_23",
            },
            {
                korean : "대치2,6층",
                english : "daechi_13",
            },
            {
                korean : "대치점 전체",
                english : "daechiTotal",
            }
        ],
        classInfo : classInfos.one
    },
    {
        academy : ["mom"],
        korean : "송도_지정석",
        english : "songdo_fixed",
        filterValue_contains : "송도_지정석",
        jobs : [
            {
                english : "desk1",
                korean : "송도 데스크",
                filterValue_contains : "송도 데스크",
                index : 8
            },
            {
                english : "coach1",
                korean : "송도 사감",
                filterValue_contains : "송도 사감",
                index : 9
            }
        ],
        questionLocation : [
            {
                korean : "송도_지정석",
                english : "songdo_fixed",
            },
            {
                korean : "송도_전체",
                english : "songdo_all"
            }
        ],
        classInfo : classInfos.two,
        seatSvg : SongdoFixedSeatSvg
    },
    {
        academy : ["mom"],
        korean : "송도_자유석",
        english : "songdo_free",
        filterValue_contains : "송도_자유석",
        questionLocation : [
            {
                korean : "송도_자유석",
                english : "songdo_free",
            },
            {
                korean : "송도_전체",
                english : "songdo_all"
            }
        ],
        classInfo : classInfos.two,
        seatSvg : SongdoFreeSeatSvg,
        isFree : true
    },
    {
        academy : ["molip"],
        korean : "몰입공간",
        english : "molip",
        filterValue_contains : "몰입공간",
        classInfo : classInfos.four,
    }
]

export default totalLocationData;