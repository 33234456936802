import { Button, Radio, Textarea } from "@mui/joy";
import { Backdrop, CircularProgress, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataGridPremium, GridFilterModel } from "@mui/x-data-grid-premium";
import React, { useEffect, useState } from "react";
import { Alert, Autocomplete, Avatar, Box, LinearProgress, Modal, Stack, TextField } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Send } from "@mui/icons-material";
import { set } from "lodash";
import totalLocationData from "../../../data/locationdata";
import Image from "@mui/icons-material/Image";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: "24px",
    boxShadow: 24,
    p: 3,
    paddingLeft: 5,
    paddingRight: 5
};

const columns = [
    {
        field: "location", headerName: "지점", width: 100
    },
    {
        field: "seat", headerName: "좌석번호", width: 80
    },
    {
        field: "name", headerName: "이름", width: 180
    },
    {
        field: "selected", headerName: "선택", width: 80
    }
]

const TotalMessageSend: React.FC<any> = (props: any) => {

    const [rows, setRows] = useState<any>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loading2, setLoading2] = useState<boolean>(false);
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            {
                id: 1,
                field: "location",
                operator: "contains",
                value: ""
            },
            {
                id: 2,
                field: "name",
                operator: "contains",
                value: ""
            },
            {
                id: 3,
                field: "selected",
                operator: "contains",
                value: ""
            }
        ]
    });
    const [alignment, setAlignment] = useState("total");
    const [alignment2, setAlignment2] = useState("all");
    const [alignment3, setAlignment3] = useState("student");
    const [selectedIds, setSelectedIds] = useState<any>([]);
    const [selectedLocations, setSelectedLocations] = useState<any>([]);
    const [message, setMessage] = useState<string>("");
    const [totalSendNumber, setTotalSendNumber] = useState<number>(0);
    const [currentSendedNumber, setCurrentSendedNumber] = useState<number>(0);
    const [data, setData] = useState<any>([]);

    const [studentData, setStudentData] = useState<any>([]);
    const [parentData, setParentData] = useState<any>([]);
    const [whichType, setWhichType] = useState("text");

    const [imgLoading, setImgLoading] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const [imgId, setImgId] = useState(0);

    const [fileName, setFileName] = useState<any>("");
    const [file, setFile] = useState<any>();

    const [isOver, setIsOver] = useState(false);



    useEffect(() => {

        getTotalUsersInfo();

    }, []);

    const notify1 = () => toast.success("클립보드에 변수가 저장되었습니다. 원하는 곳에 붙여넣기 해주세요!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const notify2 = () => toast.success("모든 메세지 전송이 완료되었습니다!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const notify3 = () => toast.error("메세지 전송 중에 에러가 발생했습니다!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });



    const getTotalUsersInfo = async () => {

        try {

            setLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/envelope/totaluserinfo", {
                method: "GET",
                headers: { "Content-Type": "application/json" },
                credentials: "include"
            });

            const result = await response.json();

            if (result.message === "success") {
                console.log(result.data);

                setStudentData(result.data);
                setParentData(result.data2);

                setData(result.data);
                setLoading(false);
            }

        } catch (e) {
            console.log(e);
        }

    }

    useEffect(() => {

        makeRows(data);

    }, [data]);

    const makeRows = (data: any) => {

        data.forEach((eachData: any) => {

            if (eachData.studentName) {
                eachData.name = `${eachData.name} (${eachData.studentName} 학부모님)`;
            }

        })

        data.forEach((eachData: any) => {
            if (eachData.location === "강남점") {
                eachData.locationOrder = 1;
            } else if (eachData.location === "대치점") {
                eachData.locationOrder = 2;
            } else if (eachData.location === "대치3층") {
                eachData.locationOrder = 3;
            } else {
                eachData.locationOrder = 0;
            }
        })

        //data.location을 강남점, 대치점, 대치3층 순으로 정렬
        data.sort((a: any, b: any) => {
            if (a.locationOrder < b.locationOrder) {
                return -1;
            } else if (a.locationOrder > b.locationOrder) {
                return 1;
            } else {
                return 0;
            }
        });

        //같은 data.location안에서 data.seat을 오름차순으로 정렬
        data.forEach((eachData: any, index: number) => {

            if (!eachData.seat || isNaN(+eachData.seat)) {
                eachData.seatOrder = 100000 + index;
            } else {
                eachData.seatOrder = +eachData.seat;
            }

        });

        data.sort((a: any, b: any) => {
            if (a.locationOrder === b.locationOrder) {
                if (a.seatOrder < b.seatOrder) {
                    return -1;
                } else if (a.seatOrder > b.seatOrder) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        });

        console.log(data);

        const newRows: any = [];
        const ids: any = [];

        data.forEach((eachData: any) => {

            const oneRow: any = {};

            oneRow.id = eachData.id;

            if (ids.includes(eachData.id)) {
                return;
            }

            ids.push(eachData.id);

            oneRow.chatRoomId = eachData.chatRoomId;
            oneRow.location = eachData.location;
            oneRow.seat = eachData.seat;
            oneRow.name = eachData.name;

            newRows.push(oneRow);
        })

        setRows([...newRows]);

    }

    const handleChangeLocation = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {

        if (!newAlignment) {
            return;
        }

        setAlignment(newAlignment);

        var value = "";

        // switch (newAlignment) {
        //     case "all":
        //         value = "";
        //         break;
        //     case "gangnam":
        //         value = "강남점";
        //         break;
        //     case "daechi":
        //         value = "대치점";
        //         break;
        //     case "daechi2":
        //         value = "대치3층";
        //         break;
        //     case "daechi3":
        //         value = "대치6층";
        //         break;
        // }

        totalLocationData.forEach((eachLocationData) => {

            if (eachLocationData.filterValue_contains === undefined) {
                return;
            }

            if (eachLocationData.english === newAlignment) {
                value = eachLocationData.filterValue_contains;
            }
        })

        const newFilterModel = { ...filterModel };

        newFilterModel.items.forEach((eachFilter: any) => {

            if (eachFilter.field === "location") {
                eachFilter.value = value;
            }

        });

        setFilterModel({ ...newFilterModel });

    }

    const handleClose = () => {
        setMessage("");
        setWhichType("text");
        setImgSrc("");
        setImgId(0);
        setOpen(false);
        setIsOver(false);
    }

    useEffect(() => {

        if (selectedIds.length === 0) {
            // setSelectedLocations([
            //     { location: "강남점", names: [] },
            //     { location: "대치점", names: [] },
            //     { location: "대치3층", names: [] },
            //     { location: "대치6층", names: []}
            // ]);

            const newSelectedLocations: any = [];

            totalLocationData.forEach((eachLocationData) => {

                if (eachLocationData.english === "total") {
                    return;
                }

                newSelectedLocations.push({
                    location: eachLocationData.korean,
                    names: []
                })
            });

            setSelectedLocations([...newSelectedLocations]);

        }

        // const selectedLocations: any = [
        //     { location: "강남점", names: [] },
        //     { location: "대치점", names: [] },
        //     { location: "대치3층", names: [] },
        //     { location: "대치6층", names: []}
        // ];

        const selectedLocations: any = [];

        totalLocationData.forEach((eachLocationData) => {

            if (eachLocationData.english === "total") {
                return;
            }

            selectedLocations.push({
                location: eachLocationData.korean,
                names: []
            })

        });

        selectedIds.forEach((eachId: any) => {

            const selectedRow = rows.find((eachRow: any) => eachRow.id === eachId);

            if (selectedRow) {

                const selectedLocation = selectedLocations.find((eachLocation: any) => eachLocation.location === selectedRow.location);

                if (selectedLocation) {
                    selectedLocation.names.push(selectedRow.name);
                }

            }

        });

        setSelectedLocations(selectedLocations);

        const newRows = rows;

        //selectedIds에 해당하는 row들을 찾아서 selected를 true로 바꿔줌
        newRows.forEach((eachRow: any) => {
            if (selectedIds.includes(eachRow.id)) {
                eachRow.selected = "✔️";
            } else {
                eachRow.selected = "";
            }
        });

        setRows([...newRows]);

    }, [selectedIds]);

    const handleChange2 = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {

        if (!newAlignment) {
            return;
        }

        setAlignment2(newAlignment);

        var value = "";

        switch (newAlignment) {
            case "all":
                value = "";
                break;
            case "selected":
                value = "✔️";
                break;
        }

        const newFilterModel = { ...filterModel };

        newFilterModel.items.forEach((eachFilter: any) => {

            if (eachFilter.field === "selected") {
                eachFilter.value = value;
            }

        });

        setFilterModel({ ...newFilterModel });

    }

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                notify1();
            })
            .catch(err => {
                // 복사 실패 시 처리
                console.error('클립보드 복사에 실패했습니다.', err);
                alert('클립보드 복사에 실패했습니다.');
            });
    };

    const wait = async (ms: number) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, ms);
        })
    }

    const letsSendTotalMessages = async () => {

        try {

            if (!message && whichType === "text") {
                console.log("noMessage");
                return;
            }

            if(!imgSrc && whichType === "img"){
                console.log("noImage");
                return;
            }

            if (selectedIds.length === 0) {
                console.log("noUser");
                return;
            }

            var targetMessage = "";

            switch (whichType) {
                case "text":
                    targetMessage = message;
                    break;
                case "img":
                    targetMessage = imgSrc;
                    break;
            }


            handleClose();
            setLoading2(true);

            console.log(selectedIds);
            console.log(targetMessage);


            // setTotalSendNumber(selectedIds.length);
            // setCurrentSendedNumber(0);

            const response = await fetch("https://peetsunbae.com/dashboard/envelope/sendMessageTotal", {
                method: "post",
                headers: {
                    "content-type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    chatString: targetMessage, selectedIds, whichType
                })
            });

            const result = await response.json();

            // for (const selectedId of selectedIds) {

            //     await sendMessage(targetMessage, selectedId);

            //     setCurrentSendedNumber(prevNumber => prevNumber + 1);

            //     await wait(2);

            // }

            setLoading2(false);

            handleClose();

            if(result.message === "success"){
                notify2();
            }else{
                notify3();
            }


        } catch (e) {
            console.log(e);
            setLoading2(false);
            notify3();
        }



    }

    const sendMessage = async (chatString: string, selectedUserId: number) => {

        try {

            if (!chatString) {
                console.log("noMessage");
                return;
            }

            if (!selectedUserId) {
                console.log("noUser");
                return;
            }

            const response = await fetch("https://peetsunbae.com/dashboard/envelope/sendMessageOneByOne", {
                method: "post",
                headers: {
                    "content-type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    chatString, studentId: selectedUserId, whichType
                })
            });

            const result = await response.json();

            console.log(result);

            if (result.message === "success") {

                if (!result.roomNumber) {
                    console.log("noRoomNumber");
                    return;
                }

                const chatRoomId = result.roomNumber;

                props.socket.emit("newTeacherMessage", chatRoomId);
            }

        } catch (e) {
            console.log(e);
        }


    }

    const handleChange3 = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {

        if (!newAlignment) {
            return;
        }

        setAlignment3(newAlignment);

        if (newAlignment === "student") {
            setData(studentData);
        }

        if (newAlignment === "parent") {
            setData(parentData);
        }

    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWhichType(event.target.value);
    };

    const fileOnChange = (e: any) => {
        console.log("gogogogogo");
        if (e.target && e.target.files.length > 0) {
            console.log(e.target.files);
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);

            setImgLoading(true);

            var formData = new FormData();
            formData.append("chat_img", e.target.files[0]);

            setOpen(true);

            fetch("https://peetsunbae.com/dashboard/envelope/img", {
                method: "post",
                credentials: "include",
                body: formData
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        console.log(result);
                        if (result.message === "success") {
                            setImgSrc(result.src);
                            setImgId(result.imgId);
                        }
                        setImgLoading(false);
                    })
            })

        }
    }

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    }

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsOver(true);
        console.log("dragEnter");
    }

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsOver(false);
        console.log("handleDragLeaver");
    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {

        e.preventDefault();
        console.log("drop");
        console.log(e.dataTransfer.files);

        if (e.dataTransfer.files.length === 0) {
            console.log("notFile");
            return;
        }

        if (e.dataTransfer.files.length !== 1) {
            alert("한 번에 한 장의 사진만 전송 가능합니다");
            return;
        }

        if (!e.dataTransfer.files[0].type.includes("image")) {
            alert("이미지 파일만 전송가능합니다");
            return;
        }

        const files = e.dataTransfer.files;

        setFile(files[0]);
        setFileName(files[0].name);

        setImgLoading(true);

        var formData = new FormData();
        formData.append("chat_img", files[0]);

        setOpen(true);

        fetch("https://peetsunbae.com/dashboard/envelope/img", {
            method: "post",
            credentials: "include",
            body: formData
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    if (result.message === "success") {
                        setImgSrc(result.src);
                        setImgId(result.imgId);
                    }
                    setImgLoading(false);
                })
        })
    }



    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: "center",
            }}>
                <div style={{
                    width: "1200px",
                    paddingBottom: "100px"
                }}>
                    <div style={{
                        marginBottom: "16px"
                    }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment3}
                            exclusive
                            onChange={handleChange3}
                        >
                            <ToggleButton value="student">학생</ToggleButton>
                            <ToggleButton value="parent">학부모</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div style={{
                        marginBottom: "16px"
                    }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment2}
                            exclusive
                            onChange={handleChange2}
                        >
                            <ToggleButton value="all">ALL</ToggleButton>
                            <ToggleButton value="selected">selected</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div style={{
                        marginBottom: "16px",
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <div>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment}
                                exclusive
                                onChange={handleChangeLocation}
                            >
                                {/* <ToggleButton value="all">ALL</ToggleButton>
                                <ToggleButton value="gangnam">강남점</ToggleButton>
                                <ToggleButton value="daechi">대치점</ToggleButton>
                                <ToggleButton value="daechi2">대치3층</ToggleButton>
                                <ToggleButton value="daechi3">대치6층</ToggleButton> */}
                                {
                                    props.user && totalLocationData.filter(locationData => locationData.academy.includes(props.user.academy)).map((eachLocationData, index) => {
                                        return (
                                            <ToggleButton value={eachLocationData.english} key={index}>{eachLocationData.korean}</ToggleButton>
                                        )
                                    })
                                }
                            </ToggleButtonGroup>
                        </div>
                        <div>
                            <TextField
                                label="이름"
                                variant="standard"
                                size="small"
                                style={{
                                    width: "200px"
                                }}
                                onChange={(e) => {
                                    const newFilterModel = { ...filterModel };

                                    newFilterModel.items.forEach((eachFilter: any) => {

                                        if (eachFilter.field === "name") {
                                            eachFilter.value = e.target.value;
                                        }

                                    });

                                    setFilterModel({ ...newFilterModel });
                                }}
                            />
                        </div>
                    </div>
                    <div style={{
                        height: "800px"
                    }}>
                        <DataGridPremium
                            rows={rows}
                            columns={columns}
                            checkboxSelection
                            disableRowSelectionOnClick
                            density="compact"
                            loading={loading}
                            filterModel={filterModel}
                            onFilterModelChange={(model) => {
                                setFilterModel(model);
                            }}
                            onRowSelectionModelChange={(model) => {
                                console.log(model);
                                setSelectedIds(model);
                            }}
                        />
                    </div>

                    <div style={{
                        marginTop: "16px",
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <Button variant="soft" color="primary"
                            disabled={selectedIds.length === 0}
                            onClick={() => {
                                setOpen(true);
                            }}
                        >메세지 작성하기
                        </Button>
                    </div>
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{
                        fontFamily: "Apple_B",
                        fontSize: "20px",
                        marginBottom: "16px"
                    }}>
                        단체메세지 보내기
                    </div>
                    <div>
                        <div style={{
                            fontFamily: "Apple_SB",
                            fontSize: "16px",
                            marginBottom: "6px"
                        }}>
                            보낼 대상
                        </div>
                        <div>
                            {/* 강남점 몇명, 대치점 몇명, 대치3층 몇명 */}
                            {
                                selectedLocations.map((eachLocation: any, index: number) => {
                                    if (eachLocation.names.length === 0) {
                                        return null;
                                    }

                                    var isNeedComma = true;

                                    if (index === selectedLocations.length - 1) {
                                        isNeedComma = false;
                                    } else if (selectedLocations[index + 1].names.length === 0) {
                                        isNeedComma = false;
                                    }

                                    return (
                                        <span>
                                            {eachLocation.location} {eachLocation.names.length}명
                                            {
                                                isNeedComma ? ", " : ""
                                            }
                                        </span>
                                    )
                                })
                            }
                        </div>
                        <div style={{
                            fontFamily: "Apple_SB",
                            fontSize: "16px",
                            marginBottom: "8px",
                            marginTop: "16px"
                        }}>
                            보낼 메세지
                        </div>
                        <div style={{
                            marginTop: "12px",
                            marginBottom: "12px"
                        }}>
                            <Radio
                                checked={whichType === "text"}
                                onChange={handleChange}
                                value={"text"}
                                label="텍스트"
                                sx={{
                                    marginRight: "16px"
                                }}
                            />
                            <Radio
                                checked={whichType === "img"}
                                onChange={handleChange}
                                value={"img"}
                                label="이미지"
                            />
                        </div>
                        {
                            whichType === "text" &&
                            <div>
                                <Textarea minRows={6} maxRows={6}
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                    }}
                                />
                            </div>
                        }
                        {
                            (whichType === "img" && !imgLoading && !imgSrc) &&
                            <form encType="multipart/formdata">
                                <label htmlFor="file">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "160px",
                                        border: "1px solid #e0e0e0",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                        backgroundColor: isOver ? "#f0f0f0" : "#ffffff"
                                    }}
                                        onDrop={(e) => {
                                            handleDrop(e);
                                        }}
                                        onDragOver={(e) => { handleDragOver(e) }}
                                        onDragEnter={(e) => { handleDragEnter(e) }}
                                        onDragLeave={(e) => { handleDragLeave(e) }}
                                    >
                                        <Image
                                            fontSize="large"
                                            color="primary"
                                        />
                                        <input onChange={(e: any) => { fileOnChange(e) }} type="file" name="file" id="file" accept="image/*" hidden />
                                        <div style={{
                                            fontSize : "14px",
                                            marginTop : "8px"
                                        }}>
                                            클릭하거나 이미지를 드래그하여 업로드
                                        </div>
                                    </div>
                                </label>
                            </form>
                        }
                        {
                            (whichType === "img" && imgLoading) &&
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "160px",
                                border: "1px solid #e0e0e0",
                                borderRadius: "8px",
                                cursor: "pointer"
                            }}>
                                <CircularProgress />
                            </div>
                        }
                        {
                            (whichType === "img" && !imgLoading && imgSrc) &&
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "160px",
                                border: "1px solid #e0e0e0",
                                borderRadius: "8px",
                                cursor: "pointer"
                            }}>
                                <img 
                                    src={`https://peetsunbae.com/${imgSrc.split("/public/")[1]}`}
                                    alt="img" style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain"
                                }} />
                            </div>
                        }
                        {
                            whichType === "text" &&
                            <div style={{
                                marginTop: "8px"
                            }}>
                                <Button variant="soft" color="neutral"
                                    onClick={() => {
                                        copyToClipboard("#{이름+}");
                                    }}
                                >
                                    이름(성포함) 변수
                                </Button>
                                <Button variant="soft" color="neutral"
                                    onClick={() => {
                                        copyToClipboard("#{이름-}");
                                    }}
                                    style={{
                                        marginLeft: "8px"
                                    }}
                                >
                                    이름(성제외) 변수
                                </Button>
                                <Button variant="soft" color="neutral"
                                    onClick={() => {
                                        copyToClipboard("#{id}");
                                    }}
                                    style={{
                                        marginLeft: "8px"
                                    }}
                                >
                                    id 변수
                                </Button>
                            </div>

                        }

                        <div>
                            <Button variant="soft" color="primary"
                                style={{
                                    marginTop: "32px"
                                }}
                                size="lg"
                                fullWidth
                                startDecorator={
                                    <Send />
                                }
                                disabled={
                                    (whichType === "text" && !message) || (whichType === "img" && !imgSrc)
                                }
                                onClick={() => {
                                    letsSendTotalMessages();
                                }}
                            >
                                보내기
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading2}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
                

            {/* <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading2}
            >
                <div style={{
                    width: "300px"
                }}>
                    {
                        totalSendNumber !== 0 ?
                            <div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                    fontFamily: "Apple_B",
                                    fontSize: "20px"
                                }}>
                                    {currentSendedNumber} / {totalSendNumber}
                                </div>
                                <div>
                                    <LinearProgress variant="determinate" value={currentSendedNumber / totalSendNumber * 100} />
                                </div>
                            </div>
                            : null
                    }
                </div>
            </Backdrop> */}

            <ToastContainer />
        </div>
    )
}

export default TotalMessageSend;