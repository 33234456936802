import React from 'react';
import { Stack } from '@mui/material';
import { Skeleton } from '@mui/material';

const SkeletonComp: React.FC<any> = () => {

    return (
        <Stack spacing={3}>
            {/* For variant="text", adjust the height via font-size */}
            <div style={{ display: "flex" }}>

                <Skeleton variant="circular" width={40} height={40} sx={{ marginRight: "24px" }} />
                <Skeleton variant="circular" width={40} height={40} />

            </div>


            <Skeleton variant="rectangular" width={1000} height={300} />
            {/* For other variants, adjust the size with `width` and `height` */}

            <Skeleton variant="rectangular" width={1000} height={300} />
        </Stack>
    )

};

export default SkeletonComp;