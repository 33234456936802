
import totalLocationData, { ClassInfo } from "./locationdata";
import {ReactComponent as SunbaeWhiteLogo} from "../svg/newlogowhite.svg";
import {ReactComponent as MomWhiteLogo} from "../svg/logo/mom/whitelogo.svg";
import {ReactComponent as SunbaeShapeLogo} from "../svg/onlyshape.svg";
import {ReactComponent as MomShapeLogo} from "../svg/logo/mom/just.svg";
import {ReactComponent as MomShapeLogo2} from "../svg/logo/mom/just2.svg";
import {ReactComponent as SunbaeLogo} from "../svg/newlogo.svg";
import {ReactComponent as MomLogo} from "../svg/logo/mom/logo.svg";
import {ReactComponent as MolipLogo} from "../svg/logo/molip/logo.svg";
import {ReactComponent as MolipWhiteLogo} from "../svg/logo/molip/logo.svg";
import {ReactComponent as MolipShapeLogo} from "../svg/logo/molip/logo.svg";
import styles from "../dashboard/dashboard.module.css";

interface PatrolTime {
    class : number;
    time : string[];
}

export const returnAccountInfo = (academy : string) => {

    const accounts : any = {
        sunbae : {
            bank : "신한은행",
            account: "110-238-365349",
            name : "고용범"
        },
        mom : {
            bank : "신한은행",
            account : "110-150-796744",
            name : "강수아"
        }
    }

    return accounts[academy];

}

export const koreanLocationToEnglish = (koreanLocation : string) => {
    
    const location = totalLocationData.find((location) => location.korean === koreanLocation);

    if(location){
        return location.english;
    }

}

export const englishLocationToKorean = (englishLocation : string) => {
    
    const location = totalLocationData.find((location) => location.english === englishLocation);

    if(location){
        return location.korean;
    }

}

export const renderSeatSvg = (where : string, user : any) => {


    const Location : any = totalLocationData.find((location) => location.english === where);

    if(Location){
        return <Location.seatSvg user={user} />;
    }

}

export const renderLogoSvg = (academy : string, kind : "logo" | "shape" | "white", className? : string, number = 0
) => {

    var newKind : any = kind;

    if(number){
        newKind = newKind + number;
    }

    console.log("renderLogoSvg", academy, kind, className, number, newKind)

    const logoInfos = [
        {academy : "sunbae", white : SunbaeWhiteLogo, shape : SunbaeShapeLogo, logo : SunbaeLogo},
        {academy : "mom", white : MomWhiteLogo, shape : MomShapeLogo, logo : MomLogo, shape2 : MomShapeLogo2},
        {academy : "molip", white : MolipWhiteLogo, shape : MolipShapeLogo, logo : MolipLogo}
    ]

    const LogoInfo : any = logoInfos.find((info) => info.academy === academy);

    if(LogoInfo){
        const LogoComponent = LogoInfo[newKind];
        return <LogoComponent className={className} />
    }


}

export const englishAcademyNameToKorean = (englishName : string) => {

    const academyNames = [
        {english : "sunbae", korean : "수능선배"},
        {english : "mom", korean : "김엄마"},
        {english : "molip", korean : "몰입공간"}
    ]

    const academy = academyNames.find((name) => name.english === englishName);

    if(academy){
        return academy.korean;
    }

}

export const returnLocationClassInfo = (location : string) : ClassInfo[] => {
    
    var classInfo : ClassInfo[] | undefined;

    totalLocationData.forEach((locationData) => {

        if(locationData.english === location){
            classInfo = locationData.classInfo;
        }

    });

    if(!classInfo){
        return [{
            classNumber: "fourth",
            start: {
                hours: 11,
                minutes: 0
            },
            end: {
                hours: 12,
                minutes: 0
            }
    
        }]
    }

    return classInfo;

}

export interface classInfoTimeInfo {
    firstTime : number;
    lastTime : number;
    saturedayLastTime : number;
}

export const returnFirstLastTime = (location : string) : classInfoTimeInfo => {

    var result = {
        firstTime : 0,
        lastTime : 0,
        saturedayLastTime : 0
    }

    var classInfo : ClassInfo[] | undefined;

    totalLocationData.forEach((locationData) => {

        if(locationData.english === location){
            classInfo = locationData.classInfo;
        }

    });

    if(!classInfo){
        return result;
    }

    classInfo.forEach((info) => {

        if(info.isFirst){
            const time = info.start.hours * 60 + info.start.minutes;
            result.firstTime = time;
        }

        if(info.isLast){

            const time = info.end.hours * 60 + info.end.minutes;
            result.lastTime = time;
            result.saturedayLastTime = time;
        }

    });

    classInfo.forEach((info) => {

        if(info.isSaturedayLast){
            
            const time = info.end.hours * 60 + info.end.minutes;
            result.saturedayLastTime = time;

        }

    });


    return result;

}

export const returnPatrolTime = (academy : string) => {

    if(!academy){
        return [];
    }

    const classDatas : Record<string, PatrolTime[]> = {
        sunbae : [
            {
                class : 1,
                time : ["8:00", "8:20", "8:40"]
            },
            {
                class : 2,
                time : ["9:00", "9:20", "9:40", "10:00"]
            },
            {
                class : 3,
                time : ["10:30", "10:50", "11:10", "11:30"]
            },
            {
                class : 4,
                time : ["13:00", "13:20", "13:40", "14:00"]
            },
            {
                class : 5,
                time : ["14:30", "14:50", "15:10", "15:30"]
            },
            {
                class : 6,
                time : ["16:00", "16:20", "16:40", "17:00"]
            },
            {
                class : 7,
                time : ["18:30", "18:50", "19:10"]
            },
            {
                class : 8,
                time : ["19:45", "20:05", "20:25"]
            },
            {
                class : 9,
                time : ["21:00", "21:20", "21:40"]
            },
        ],
        mom : [
            {
                class : 1,
                time : ["8:00", "8:20", "8:40"]
            },
            {
                class : 2,
                time : ["9:00", "9:20", "9:40", "10:00"]
            },
            {
                class : 3,
                time : ["10:20", "10:40", "11:00", "11:20", "11:40"]
            },
            {
                class : 4,
                time : ["13:00", "13:20", "13:40", "14:00"]
            },
            {
                class : 5,
                time : ["14:20", "14:40", "15:00", "15:20", "15:40"]
            },
            {
                class : 6,
                time : ["16:00", "16:20", "16:40"]
            },
            {
                class : 7,
                time : ["17:00", "17:20", "17:40"]
            },
            {
                class : 8,
                time : ["19:00", "19:20", "19:40", "20:00"]
            },
            {
                class : 9,
                time : ["20:20", "20:40", "21:00"]
            },
            {
                class : 10,
                time : ["21:20", "21:40", "22:00"]
            },
            {
                class : 11,
                time : ["22:20", "22:40", "23:00"]
            },
            {
                class : 12,
                time : ["23:20", "23:40"]
            }
        ]
    }

    const patrolTime = classDatas[academy];

    if(!patrolTime){
        return [];
    }

    return patrolTime;

}

export const dayNumberToKorean = (dayNumber: number) => {

    const dayNames = ["일", "월", "화", "수", "목", "금", "토"];

    return dayNames[dayNumber];

}

export const returnNeedAuthorizeAcademy = () => {

    const list = ["sunbae"];

    return list;

}

export const returnAuthorizedJob = (academy : string) => {

    const job = ["desk", "etc", "manager", "sagam"];

    return job;

}