import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import koLocale from 'date-fns/locale/ko'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { GridRenderCellParams, GridFilterModel, DataGridPremium, GridRowsProp, GridColDef, GridToolbar, LicenseInfo, useGridApiRef, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { eachDayOfInterval } from 'date-fns';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { createStyles, makeStyles } from '@mui/styles';
import styles from '../../componentsStyle/upload.module.css';
import { createTheme, darken, lighten } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import Button2 from '@mui/joy/Button';

import { ReactComponent as Pen } from "../../../../src/svg/pen-to-square-light.svg";
import { ReactComponent as Trash } from "../../../../src/svg/trash-can-light.svg";
import { CssVarsProvider } from '@mui/joy';
import WorkloadModal from '../etc/workloadmodal';
import totalLocationData from '../../../data/locationdata';
import SkeletonComp from './etc/skeletoncomp';

LicenseInfo.setLicenseKey("7e2ba431ba4eff510b2133d8ca5534afTz02NTM0MixFPTE3MTQyMDk3Njk2NzgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");


const defaultTheme = createTheme();
const useStyles2 = makeStyles(
  (theme) => {
    const getBackgroundColor = (color: any) =>
      theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getHoverBackgroundColor = (color: any) =>
      theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    return {
      root: {
        '& .super-app-theme--처리완료': {
          backgroundColor: getBackgroundColor(theme.palette.info.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
          },
        },
        '& .super-app-theme--Filled': {
          backgroundColor: getBackgroundColor(theme.palette.success.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
          },
        },
        '& .super-app-theme--확인': {
          backgroundColor: getBackgroundColor(theme.palette.warning.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
          },
        },
        '& .super-app-theme--미확인': {
          backgroundColor: getBackgroundColor(theme.palette.error.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
          },
        },
        '& .timeout': {
          color: theme.palette.error.main,
        },
      },
    };
  },
  { defaultTheme },
);




interface GridCellExpandProps {
  value: string;
  width: number;
}


const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignItems: 'center',
      lineHeight: '24px',
      width: '100%',
      height: '100%',
      position: 'relative',
      display: 'flex',
      '& .cellValue': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  }),
);


function isOverflown(element: Element): boolean {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}


const GridCellExpand = React.memo(function GridCellExpand(
  props: GridCellExpandProps,
) {
  const { width, value } = props;
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <div
      ref={wrapper}
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <div ref={cellValue} className="cellValue">
        {value}
      </div>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </div>
  );
});

function renderCellExpand(params: GridRenderCellParams<any>) {
  return (
    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
  );
}








LicenseInfo.setLicenseKey("3124a63cf796a421a7ac55c9bf753845Tz01NjM1MCxFPTE3MDMwMzU5NjMxNzEsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=");

const columns: GridColDef[] = [
  { field: 'time', headerName: '시간', width: 120, filterable: false },
  { field: 'description', headerName: '내용', width: 730, renderCell: renderCellExpand, filterable: true },
  { field: 'answer', headerName: '완료 답변', width: 150, renderCell: renderCellExpand, filterable: false },
  { field: 'answerTime', headerName: '완료시간', width: 150, renderCell: renderCellExpand, filterable: false },
  { field: 'answerName', headerName: '답변인', width: 150, renderCell: renderCellExpand, filterable: true },
  { field: 'location', headerName: '장소', width: 100 },
  { field: 'ip', headerName: 'IP', width: 150, renderCell: renderCellExpand, filterable: false },
  { field: 'block', headerName: '', width: 150, renderCell: renderCellExpand, filterable: false },

];


const columns2: GridColDef[] = [
  { field: 'day', headerName: '요일', width: 150, filterable: true },
  { field: 'time', headerName: '시간', width: 120, filterable: true },
  { field: 'description', headerName: '내용', width: 730, renderCell: renderCellExpand, filterable: true },
  { field: 'location', headerName: '장소', width: 100 },
  { field: "createdAt", headerName: "생성일시", width: 150 }
];

const TotalWorkLoad: React.FC<any> = (props) => {
  const classes = useStyles2();
  const [alignment, setAlignment] = React.useState('total');
  const [alignment2, setAlignment2] = useState("total");

  const [rows, setRows] = useState<any>([]);
  const [rows2, setRows2] = useState<any>([]);
  const [loading2, setLoading2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const [count, setCount] = useState(1);

  const [update, setUpdate] = useState(0);
  const [currentTime, setCurrentTime] = useState(new Date().getHours() * 60 + new Date().getMinutes());

  const [targetdate, setTargetdate] = useState<any>();

  const [textfieldValue, setTextfieldValue] = useState("");
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

  const [disabled1, setDisabled1] = useState(true);
  const [disabled2, setDisabled2] = useState(true);


  const [open, setOpen] = React.useState(false);
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();

  const [id, setId] = useState(0);
  const [location, setLocation] = useState([false, false, false]);
  const [day, setDay] = useState([false, false, false, false, false, false, false]);
  const [description, setDescription] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const apiRef = useGridApiRef();
  const [data, setData] = useState<any>();



  useEffect(() => {
    const date = new Date();
    const yesterdayTime = date.setDate(date.getDate() - 1);
    const yesterday = new Date(yesterdayTime);
    setTargetdate(yesterday);
  }, [])



  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [
    ],
  });

  const [filterModel2, setFilterModel2] = React.useState<GridFilterModel>({
    items: [
      {
        id: 2, field: "location", operator: "contains", value: "",
      },
      {
        id: 3, field: "description", operator: "contains", value: ""
      }
    ],
  });

  const textFieldChange = (e: any) => {


    setTextfieldValue(e.target.value);

    const newFilterModel = filterModel2;

    newFilterModel.items.forEach((each) => {
      if (each.id === 3) {
        each.value = e.target.value;
      }
    })

    setFilterModel2({ ...newFilterModel });

  }


  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {

    if (!newAlignment) {
      return;
    }

    console.log(newAlignment);
    setAlignment(newAlignment);

    totalLocationData.forEach((location) => {
      if (!location.jobs) {
        return null;
      }

      location.jobs.forEach((job) => {
        if (job.english === newAlignment) {
          setFilterModel({
            items: [
              { id: 1, field: 'location', operator: 'contains', value: job.filterValue_contains },
            ],
          });
        }
      })
    })

    // switch (newAlignment) {
    //   case "all":
    //     setFilterModel({
    //       items: []
    //     });
    //     break;
    //     case "all":
    //       setFilterModel({
    //         items: []
    //       });
    //       break;
    //     case "desk":
    //       setFilterModel({
    //         items: [
    //           { id: 1, field: 'location', operator: 'contains', value: '강남 데스크' },
    //         ],
    //       });
    //       break;
    //     case "coach":
    //       setFilterModel({
    //         items: [
    //           { id: 1, field: 'location', operator: 'contains', value: '강남 사감' },
    //         ],
    //       });
    //       break;
    //     case "desk2":
    //       setFilterModel({
    //         items: [
    //           { id: 1, field: 'location', operator: 'contains', value: '대치 데스크' },
    //         ],
    //       });
    //       break;
    //     case "coach2":
    //       setFilterModel({
    //         items: [
    //           { id: 1, field: 'location', operator: 'contains', value: '대치 사감' },
    //         ],
    //       });
    //       break;
    //     case "desk3":
    //       setFilterModel({
    //         items: [
    //           { id: 1, field: 'location', operator: 'contains', value: '대치 3층 데스크' },
    //         ],
    //       });
    //       break;
    //     case "coach3":
    //       setFilterModel({
    //         items: [
    //           { id: 1, field: 'location', operator: 'contains', value: '대치 3층 사감' },
    //         ],
    //       });
    //       break;
    //     case "desk4":
    //       setFilterModel({
    //         items: [
    //           { id: 1, field: 'location', operator: 'contains', value: '대치 6층 데스크' },
    //         ],
    //       });
    //       break;
    //     case "coach4":
    //       setFilterModel({
    //         items: [
    //           { id: 1, field: 'location', operator: 'contains', value: '대치 6층 사감' },
    //         ],
    //       });
    //       break;
    // }

  };

  const handleChange2 = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {


    if (!newAlignment) {
      return;
    }

    console.log(newAlignment);
    setAlignment2(newAlignment);

    const newFilterModel = filterModel2;

    totalLocationData.forEach((location) => {

      if (!location.jobs) {
        return null;
      }

      location.jobs.forEach((job) => {
        if (job.english === newAlignment) {

          console.log(job.filterValue_contains);

          filterModel2.items.forEach((each) => {

            if (each.id === 2) {
              each.value = job.filterValue_contains;
            }

          });

          // setFilterModel2({
          //   items: [
          //     { id: 1, field: 'location', operator: 'contains', value: job.filterValue_contains },
          //   ],
          // });
        }
      })
    })

    setFilterModel2({ ...newFilterModel });

  };



  const getList = async (count: number) => {
    setLoading2(true);
    var token = "";
    if (window.electron) {
      token = await window.electron.sendMessageApi.getToken();
    }

    fetch(`https://peetsunbae.com/dashboard/report/totalworkday?count=${count}`, {
      method: "GET",
      headers: { "Authorization": token },
      credentials: "include",
    }).then((response: any) => {
      response.json()
        .then((result: any) => {
          console.log(result);
          setLoading2(false);
          const newRows: any = [];
          result.data.forEach((each: any) => {
            const oneRow: any = {};
            oneRow.id = each.id;
            oneRow.time = `${Math.floor(each.startTime / 60) < 10 ? "0" + Math.floor(each.startTime / 60) : Math.floor(each.startTime / 60)}:${each.startTime % 60 < 10 ? "0" + each.startTime % 60 : each.startTime % 60}~${Math.floor(each.endTime / 60) < 10 ? "0" + Math.floor(each.endTime / 60) : Math.floor(each.endTime / 60)}:${each.endTime % 60 < 10 ? "0" + each.endTime % 60 : each.endTime % 60}`
            oneRow.description = each.description;
            oneRow.location = each.location;
            oneRow.startTime = each.startTime;
            oneRow.endTime = each.endTime;
            if (each.answer.answer) {
              oneRow.answer = each.answer.answer;
              const answerDate = new Date(each.answer.answerTime);
              oneRow.answerTime = `${answerDate.getHours() < 10 ? "0" + answerDate.getHours() : answerDate.getHours()} : ${answerDate.getMinutes() < 10 ? "0" + answerDate.getMinutes() : answerDate.getMinutes()}`;
              oneRow.answerName = each.answer.answerUserName;
              oneRow.ip = each.answer.ip;
            }
            newRows.push(oneRow);
          })
          setRows([...newRows]);
        })
    })
  }


  useEffect(() => {
    setLoading(true);

    getList(1);

  }, []);

  const start = async () => {
    var token = "";
    if (window.electron) {
      token = await window.electron.sendMessageApi.getToken();
    }

    fetch("https://peetsunbae.com/dashboard/report/totalwork", {
      method: "GET",
      headers: { "Authorization": token },
      credentials: "include",
    }).then((response: any) => {
      response.json()
        .then((result: any) => {
          setLoading(false);
          console.log(result);
          const data: any = result.data;

          data.sort(function (a: any, b: any) {
            if (a.startTime > b.startTime) {
              return 1;
            }
            if (a.startTime === b.startTime) {
              if (a.endTime >= b.endTime) {
                return 1;
              } else {
                return -1;
              }
            }
            if (a.startTime < b.startTime) {
              return -1;
            }
          });

          console.log(data);
          setData([...data]);
          const newRows: any = [];

          data.forEach((each: any, number: number) => {

            each.dayString = [];

            each.day.forEach((boolean: boolean, index: number) => {
              if (boolean) {
                switch (index) {
                  case 0:
                    each.dayString.push("일");
                    break;
                  case 1:
                    each.dayString.push("월");
                    break;
                  case 2:
                    each.dayString.push("화");
                    break;
                  case 3:
                    each.dayString.push("수");
                    break;
                  case 4:
                    each.dayString.push("목");
                    break;
                  case 5:
                    each.dayString.push("금");
                    break;
                  case 6:
                    each.dayString.push("토");
                    break;
                }
              }
            })

            const oneRow: any = {};
            oneRow.id = each.id;
            oneRow.time = `${Math.floor(each.startTime / 60) < 10 ? "0" + Math.floor(each.startTime / 60) : Math.floor(each.startTime / 60)}:${each.startTime % 60 < 10 ? "0" + each.startTime % 60 : each.startTime % 60}~${Math.floor(each.endTime / 60) < 10 ? "0" + Math.floor(each.endTime / 60) : Math.floor(each.endTime / 60)}:${each.endTime % 60 < 10 ? "0" + each.endTime % 60 : each.endTime % 60}`;
            oneRow.location = each.location;
            oneRow.description = each.description;
            oneRow.startTime = each.startTime;
            oneRow.endTime = each.endTime;
            oneRow.day = each.dayString;

            const newDate = new Date(each.createdAt);

            const createdAtString = `${newDate.getFullYear()}-${newDate.getMonth() + 1 < 10 ? "0" + (newDate.getMonth() + 1) : newDate.getMonth() + 1}-${newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate()}`;

            oneRow.createdAt = createdAtString;
            newRows.push(oneRow);
          })

          // data.forEach((each: any, number: number) => {
          //   each.day.forEach((boolean: Boolean, index: number) => {
          //     if (boolean) {
          //       const oneRow: any = {};
          //       oneRow.id = Math.random();
          //       oneRow.time = `${Math.floor(each.startTime / 60) < 10 ? "0" + Math.floor(each.startTime / 60) : Math.floor(each.startTime / 60)}:${each.startTime % 60 < 10 ? "0" + each.startTime % 60 : each.startTime % 60}~${Math.floor(each.endTime / 60) < 10 ? "0" + Math.floor(each.endTime / 60) : Math.floor(each.endTime / 60)}:${each.endTime % 60 < 10 ? "0" + each.endTime % 60 : each.endTime % 60}`
          //       oneRow.location = each.location;
          //       oneRow.description = each.description;
          //       oneRow.startTime = each.startTime;
          //       oneRow.endTime = each.endTime;
          //       oneRow.day = [];
          //       switch (index) {
          //         case 0:
          //           oneRow.day.push("일");
          //           break;
          //         case 1:
          //           oneRow.day.push("월");
          //           break;
          //         case 2:
          //           oneRow.day.push("화");
          //           break;
          //         case 3:
          //           oneRow.day.push("수");
          //           break;
          //         case 4:
          //           oneRow.day.push("목");
          //           break;
          //         case 5:
          //           oneRow.day.push("금");
          //           break;
          //         case 6:
          //           oneRow.day.push("토");
          //           break;
          //       }
          //       oneRow.block = "--------------------------------------";
          //       newRows.push(oneRow);
          //     }
          //   })
          // });

          setRows2([...newRows]);
        })
    })
  }


  useEffect(() => {

    start();

  }, [update, props.update]);



  const previousDay = (e: any) => {
    const newCount = count + 1;
    getList(newCount);
    setCount(newCount);

    if (targetdate) {
      const newDate = new Date(targetdate.setDate(targetdate.getDate() - 1));
      setTargetdate(newDate);
    }
  }

  const nextDay = (e: any) => {
    if (count > 1) {
      const newCount = count - 1;
      getList(newCount);
      setCount(newCount);

      if (targetdate) {
        const newDate = new Date(targetdate.setDate(targetdate.getDate() + 1));
        setTargetdate(newDate);
      }
    }
  }

  const handleDelete = async (e: any) => {

    console.log("..");
    console.log("dddddd");

    if (!selectionModel) {
      return;
    }

    if (selectionModel.length === 0) {
      return;
    }

    if (window.confirm("삭제를 진행할까요?")) {

    } else {
      return;
    }

    var token = "";
    if (window.electron) {
      token = await window.electron.sendMessageApi.getToken();
    }

    fetch("https://peetsunbae.com/dashboard/report/workload", {
      method: "delete",
      headers: {
        "content-type": "application/json",
        "Authorization": token
      },
      credentials: "include",
      body: JSON.stringify({
        selectionModel
      })
    }).then((response: any) => {
      response.json()
        .then((result: any) => {
          if (result.message === "success") {
            alert("삭제 성공");
            setUpdate(Math.random());
          }

        })
    })

  }

  const handleRefresh = () => {
    setUpdate(Math.random());
  }


  const handleUpdate = () => {

    if (selectionModel.length !== 1) {
      return;
    }

    const id = selectionModel[0];

    console.log(id);

    if (!data) {
      return;
    }

    var selectedData: any;

    data.forEach((each: any) => {
      if (each.id === id) {
        selectedData = each;
      }
    })

    console.log(selectedData);

    setId(selectedData.id);
    setDescription(selectedData.description);
    setDay(selectedData.day);

    const newLocation = new Array(10).fill(false);

    // switch (selectedData.location) {
    //   case "강남 데스크":
    //     newLocation[0] = true;
    //     setLocation([...newLocation]);
    //     break;
    //   case "강남 사감":
    //     newLocation[1] = true;
    //     setLocation([...newLocation]);
    //     break;
    //   case "대치 데스크":
    //     newLocation[2] = true;
    //     setLocation([...newLocation]);
    //     break;
    //   case "대치 사감":
    //     newLocation[3] = true;
    //     setLocation([...newLocation]);
    //     break;
    //   case "대치 3층 데스크":
    //     newLocation[4] = true;
    //     setLocation([...newLocation]);
    //     break;
    //   case "대치 3층 사감":
    //     newLocation[5] = true;
    //     setLocation([...newLocation]);
    //     break;
    // }

    var index = 0;

    totalLocationData.forEach((location) => {

      if (!location.jobs) {
        return;
      }

      if (location.english === "total") {
        return;
      }

      location.jobs.forEach((job) => {

        if (job.korean === selectedData.location) {
          newLocation[index] = true;
          setLocation([...newLocation]);
        }

        index++;

      })

    })

    const startTime = selectedData.startTime;
    const endTime = selectedData.endTime;

    const startHours = Math.floor(startTime / 60);
    const startMinutes = startTime % 60;

    const endHours = Math.floor(endTime / 60);
    const endMinutes = endTime % 60;

    const newDate = new Date();

    const startDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), startHours, startMinutes, 0);
    const endDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), endHours, endMinutes, 0);

    setStartTime(startDate);
    setEndTime(endDate);

    setOpen(true);

  }



  return (
    <div>
      {
        loading ? <SkeletonComp /> :
          <>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment2}
                  exclusive
                  onChange={handleChange2}
                >
                  {/* <ToggleButton value="all">ALL</ToggleButton>
            <ToggleButton value="desk">강남 데스크</ToggleButton>
            <ToggleButton value="coach">강남 사감</ToggleButton>
            <ToggleButton value="desk2">대치 데스크</ToggleButton>
            <ToggleButton value="coach2">대치 사감</ToggleButton>
            <ToggleButton value="desk3">대치 3층 데스크</ToggleButton>
            <ToggleButton value="coach3">대치 3층 사감</ToggleButton>
            <ToggleButton value="desk4">대치 6층 데스크</ToggleButton>
            <ToggleButton value="coach4">대치 6층 사감</ToggleButton> */}

                  {
                    props.user && totalLocationData.map((location) => {
                      if (!location.jobs) {
                        return null;
                      }

                      if (!location.academy.includes(props.user.academy)) {
                        return null;
                      }

                      return location.jobs.map((job, index) => {
                        return (
                          <ToggleButton key={index} value={job.english}>{job.korean}</ToggleButton>
                        )
                      })
                    })

                  }

                </ToggleButtonGroup>
              </div>
              <div>
              </div>
            </div>
            <div className={styles.mysearchDate} style={{ marginTop: "18px", marginBottom: "12px" }}>
              <CssVarsProvider>
                <div>
                  <span style={{ marginRight: "12px" }}>
                    <Button2 variant="soft" disabled={disabled1} onClick={handleDelete} >삭제</Button2>
                  </span>
                  <span>
                    <Button2 variant="soft" disabled={disabled2} onClick={handleUpdate}>수정</Button2>
                  </span>
                </div>
              </CssVarsProvider>
              <div>
                <TextField value={textfieldValue} variant="standard" placeholder='내용을 검색하세요' onChange={textFieldChange} />
              </div>
            </div>
            <div className={classes.root} style={{ height: 500, width: '100%', backgroundColor: "white" }}>
              <DataGridPremium loading={loading} rows={rows2} columns={columns2}
                density="compact"
                components={{ Toolbar: GridToolbar }}
                // disableSelectionOnClick={true}
                apiRef={apiRef}
                checkboxSelection
                filterModel={filterModel2}
                onFilterModelChange={(model) => setFilterModel2(model)}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(newSelectionModel) => {
                  if (newSelectionModel.length > 0) {
                    setDisabled1(false);
                  } else {
                    setDisabled1(true);
                  }

                  if (newSelectionModel.length === 1) {
                    setDisabled2(false);
                  } else {
                    setDisabled2(true);
                  }

                  console.log(newSelectionModel);
                  setSelectionModel(newSelectionModel);
                }}
              />
            </div>
            <div style={{ marginTop: "64px", marginBottom: "6px", display: "flex", justifyContent: "space-between" }}>
              <div>
                <div>
                  <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <Button onClick={previousDay}>◁</Button>
                    <Button onClick={nextDay}>▷</Button>
                  </ButtonGroup>
                </div>
                <div className={styles.targetdate}>
                  {targetdate &&
                    `${targetdate.getFullYear()}-${targetdate.getMonth() + 1}-${targetdate.getDate()}`
                  }
                </div>
              </div>
              <div>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                >
                  {/* <ToggleButton value="all">ALL</ToggleButton>
            <ToggleButton value="desk">데스크</ToggleButton>
            <ToggleButton value="coach">사감</ToggleButton>
            <ToggleButton value="desk2">대치 데스크</ToggleButton>
            <ToggleButton value="coach2">대치 사감</ToggleButton>
            <ToggleButton value="desk3">대치 3층 데스크</ToggleButton>
            <ToggleButton value="coach3">대치 3층 사감</ToggleButton>
            <ToggleButton value="desk4">대치 6층 데스크</ToggleButton>
            <ToggleButton value="coach4">대치 6층 사감</ToggleButton> */}
                  {
                    props.user && totalLocationData.map((location) => {



                      if (!location.jobs) {
                        return null;
                      }

                      if (!location.academy.includes(props.user.academy)) {
                        return null;
                      }

                      return location.jobs.map((job, index) => {
                        return (
                          <ToggleButton value={job.english} key={index}>{job.korean}</ToggleButton>
                        )
                      })

                    })
                  }

                </ToggleButtonGroup>
              </div>
            </div>

            <div className={classes.root} style={{ height: 500, width: '100%', backgroundColor: "white" }}>
              <DataGridPremium loading={loading2} rows={rows} columns={columns}
                density="compact"
                components={{ Toolbar: GridToolbar }}
                filterModel={filterModel}
                onFilterModelChange={(model) => setFilterModel(model)}
                disableRowSelectionOnClick={false}
                getRowClassName={(params: any) => {
                  if (!params.row.answer) {
                    return (
                      "super-app-theme--미확인"
                    )
                  } else {
                    return (
                      "super-app-theme--처리완료"
                    )
                  }
                }
                }
                getCellClassName={(params: any) => {
                  if (params.field != "answerTime") {
                    return '';
                  } else if (!params.value) {
                    return '';
                  } else {
                    const hours = +(params.value.split(":")[0]);
                    const minutes = +(params.value.split(":")[1]);
                    const answerTimeValue = hours * 60 + minutes;
                    // console.log(params);
                    return (params.row.startTime > answerTimeValue || params.row.endTime < answerTimeValue) ? "timeout" : "";
                  }
                }}
              />
            </div>

            <div className={styles.mysearchDescription}>
              * 미완료 - 빨강색, 완료 - 파랑색<br />
            </div>

            <WorkloadModal
              id={id}
              open={open}
              location={location}
              day={day}
              startTime={startTime}
              endTime={endTime}
              description={description}
              handleClose={handleClose}
              handleRefresh={handleRefresh}
              user={props.user}
            />


          </>

      }

    </div>
  )
}

export default TotalWorkLoad;