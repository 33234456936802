import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import InspectPhone from "./components/inspectphone";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AttendanceCheck from "./components/attendancecheck";
import DemeritMain from "./demerit/demeritmain";
import TotalAccessControl from "./page/totalaccesscontrol";
import PureStudyTimeCalc from "./page/purestudytimecalc";
import SkeletonComp from "../etc/skeletoncomp";


const NewAttendance: React.FC<any> = (props) => {

    const [value, setValue] = React.useState('1');
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        //300ms 후에 로딩을 false로 변경
        setTimeout(() => {
            setLoading(false);
        }, 150);


    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const notify1 = () => toast.success("저장 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify2 = () => toast.success("문자메세지를 전송했습니다!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify3 = () => toast.success("전화 요청을 보냈습니다!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify4 = () => toast.success("폰 미제출 알림톡을 보냈습니다!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });


    return (
        <div>
            {
                loading ? <SkeletonComp /> : <>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label={<span style={{ fontFamily: "Apple_B", fontSize: "18px" }}>출석 체크</span>} value="1" />
                                    <Tab label={<span style={{ fontFamily: "Apple_B", fontSize: "18px" }}>오늘의 벌점</span>} value="2" />
                                    <Tab label={<span style={{ fontFamily: "Apple_B", fontSize: "18px" }}>전체 출입기록</span>} value="3" />
                                    <Tab label={<span style={{ fontFamily: "Apple_B", fontSize: "18px" }}>순 공부시간</span>} value="4" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <AttendanceCheck location={props.location} notify1={notify1} notify2={notify2} notify3={notify3} notify4={notify4} socket={props.socket}
                                    messageUpdate={props.messageUpdate} unreadMessage={props.unreadMessage} user={props.user}
                                />
                            </TabPanel>
                            <TabPanel value="2">
                                <DemeritMain location={props.location} socket={props.socket} user={props.user}
                                    messageUpdate={props.messageUpdate} unreadMessage={props.unreadMessage}
                                />
                            </TabPanel>
                            <TabPanel value="3">
                                <TotalAccessControl location={props.location} />
                            </TabPanel>
                            <TabPanel value="4">
                                <PureStudyTimeCalc location={props.location} />
                            </TabPanel>
                        </TabContext>
                    </Box>
                </>
            }


            <ToastContainer />
        </div>
    )

}

export default NewAttendance;